.content {
    height: calc(100vh - 142px);
}

.item-no {
    font-size: 3rem;
}

.item-name {
    font-size: 5rem;
}

.bid-amount {
    font-size: 5.5rem;
    font-weight: bold;
}

.item-image {
    max-width: 100%;
    max-height: 500px;
}